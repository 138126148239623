import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useListingRedirect } from '~hooks/useListingRedirect';

import Breadcrumb from '~components/breadcrumbs';
import Hero from '~components/hero';

import ContactForm from './form';

import './style.scss';

const Contact = () => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth0();
  const listingRedirect = useListingRedirect();

  useLayout({ displayTopCTABar: false, displaySignIn: true });
  useSeo(
    intl.formatMessage({
      id: 'containers.contact.seo.title',
    })
  );

  return (
    <>
      {!isAuthenticated && (
        <aside className="c-subheader">
          <div className="l-container">
            <Breadcrumb.Wrapper>
              <Breadcrumb.Crumb>
                <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                  {intl.formatMessage({
                    id: 'breadcrumbs.home',
                  })}
                </Breadcrumb.CrumbLink>
              </Breadcrumb.Crumb>
              <Breadcrumb.Crumb>
                <FormattedMessage id="routes.contact">
                  {(msg) => (
                    <Breadcrumb.CrumbLink to={`/${msg}`} index={2}>
                      {intl.formatMessage({
                        id: 'breadcrumbs.contact',
                      })}
                    </Breadcrumb.CrumbLink>
                  )}
                </FormattedMessage>
              </Breadcrumb.Crumb>
            </Breadcrumb.Wrapper>
          </div>
        </aside>
      )}

      <section className="c-page l-container">
        <div className="c-page__main">
          <Hero.Wrapper>
            <Hero.Title>
              {intl.formatMessage({
                id: 'containers.contact.headline',
              })}
            </Hero.Title>
          </Hero.Wrapper>
          <div className="c-contact">
            <ContactForm />
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;

import React, { useRef } from 'react';
import cx from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

import withControlled from '../form/with-controlled';

import './style.scss';

export const Captcha = ({
  name,
  className,
  value,
  disabled = false,
  inputRef = useRef(),
  errors,
  onChange = () => {},
  googleRecaptchaSiteKey,
  isInvalid,
  ...props
}) => {
  return (
    <div
      className={cx('c-form__captcha', {
        'c-form__captcha--invalid': errors && !disabled,
        [className]: !!className,
      })}
    >
      <ReCAPTCHA
        name={name}
        sitekey={googleRecaptchaSiteKey}
        onChange={onChange}
        value={value}
        ref={inputRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {errors && !disabled && <div className="c-input__error">{errors.message}</div>}
    </div>
  );
};

export const CaptchaControlled = withControlled(Captcha);

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import PromptService from '~services/prompt';

import * as REST from '~services/rest';
import NotificationsActions from '~redux/notifications';
import { UserContext } from '~contexts/user';

import Form from '~components/forms/form';
import { InputControlled } from '~components/forms/input';
import { TextareaControlled } from '~components/forms/textarea';
import { CaptchaControlled } from '~components/forms/captcha';

import Button from '~components/button';

const ContactForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const googleRecaptchaSiteKey = useSelector((state) => state.domainConfig.googleRecaptchaSiteKey);
  const captchaRef = useRef(null);

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required().max(256),
    Email: Yup.string().email().required().max(256),
    Message: Yup.string().required().max(2048),
    Captcha: Yup.string().required(),
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      Name: user?.firstName ? `${user.firstName}` : '',
      Email: user?.email || '',
      Message: '',
      Captcha: '',
    },
  });

  useEffect(() => {
    methods.reset(
      {
        Name: user?.firstName ? `${user.firstName}` : '',
        Email: user?.email || '',
        Message: '',
        Captcha: '',
      },
      {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isDirty: false, // dirty will not be reset
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  }, [user]);

  useEffect(async () => {
    if (!isSubmitting) {
      return;
    }

    const formData = methods.getValues();

    try {
      await REST.post({
        name: `chats/contact`,
        params: {
          sender_email: formData.Email,
          sender_name: formData.Name,
          message: formData.Message,
          captcha: formData.Captcha,
        },
        shouldThrowError: true,
      });

      dispatch(
        NotificationsActions.addNotification(
          intl.formatMessage({
            id: 'containers.contact.form.notification',
          })
        )
      );

      methods.reset(
        {
          Name: user?.firstName ? `${user.firstName}` : '',
          Email: user?.email || '',
          Message: '',
          Captcha: '',
        },
        {
          errors: true, // errors will not be reset
          dirtyFields: true, // dirtyFields will not be reset
          isDirty: true, // dirty will not be reset
          isSubmitted: false,
          touched: false,
          isValid: false,
          submitCount: false,
        }
      );
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    } catch (err) {
      dispatch(
        NotificationsActions.addNotification(
          intl.formatMessage({
            id: 'containers.contact.form.notification-error',
          })
        )
      );

      if (captchaRef.current) {
        captchaRef.current.reset();
      }

      if (err.response.status === 500) {
        PromptService.alert({
          title: intl.formatMessage({
            id: 'prompts.server-error.title',
            defaultMessage: 'Server error',
          }),
          content: intl.formatMessage({
            id: 'prompts.server-error.content',
            defaultMessage: 'An unexpected server error occurred.',
          }),
        }).then(null);
        return;
      }

      methods.setError('Captcha', {
        message: intl.formatMessage({
          id: 'containers.contact.form.captcha-error',
          defaultMessage: 'Invalid captcha',
        }),
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  const handleFormSubmit = () => {
    if (isEmpty(methods.errors) || !isSubmitting) {
      setIsSubmitting(true);
    }
  };

  return (
    <Form methods={methods} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
      <div className="c-fieldset">
        <div className="c-fieldset__label">
          {intl.formatMessage({
            id: 'containers.contact.form.fields.name',
          })}
        </div>
        <FormattedMessage id="containers.contact.form.fields.name">
          {(msg) => <InputControlled type="text" autoComplete="on" name="Name" placeholder={msg} />}
        </FormattedMessage>
      </div>
      <div className="c-fieldset">
        <div className="c-fieldset__label">
          {intl.formatMessage({
            id: 'containers.contact.form.fields.email',
          })}
        </div>
        <FormattedMessage id="containers.contact.form.fields.email">
          {(msg) => <InputControlled type="email" autoComplete="on" name="Email" placeholder={msg} />}
        </FormattedMessage>
      </div>
      <div className="c-fieldset">
        <div className="c-fieldset__label">
          {intl.formatMessage({
            id: 'containers.contact.form.fields.message',
          })}
        </div>
        <FormattedMessage id="containers.contact.form.fields.message">
          {(msg) => (
            <TextareaControlled
              name="Message"
              className="c-contact__textarea cy-contact__message"
              placeholder={msg}
              isAutoExpand
            />
          )}
        </FormattedMessage>
      </div>
      <div className="c-fieldset c-fieldset--captcha">
        <CaptchaControlled name="Captcha" googleRecaptchaSiteKey={googleRecaptchaSiteKey} inputRef={captchaRef} />
      </div>
      <div className="c-form__buttons">
        <Button
          disabled={!isEmpty(methods.errors) || isSubmitting}
          isLoading={isSubmitting}
          name="submit"
          className="c-btn"
        >
          {intl.formatMessage({
            id: 'containers.contact.form.submit',
          })}
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
